import { get, post } from '@/utils/http.js';

/*
function:获取题目列表
author:ysl
time:2021/03/22
*/

export function getQuestions(data: any) {
    return get('/answer/question/single', data);
}

/*
function:定时缓存题目
author:ysl
time:2021/03/22
*/

export function postMobileSave(data: any) {
    return post('/answer/mobile/save', data);
}

/*
function:交卷
author:ysl
time:2021/03/22
*/

export function postMobileManagement(data: any) {
    return post('/answer/mobile/management', data);
}

/*
function:获取token
author:YSK
time:2023/10/27
*/
export function getQinToken(data: any) {
    return get('/assets/qiniupng/token/GET', data);
}
